import React from 'react'
import { Container } from 'react-bootstrap'
import '../styles/Section.css'

export default function Section ({ children }) {
  return (
    <Container className='section'>
      {children}
    </Container>
  )
}

Section.Header = function Header ({ children }) {
  return (
    <div className='section-header'>
      {children}
    </div>
  )
}

Section.Body = function Body ({ children }) {
  return (
    <div className='section-body'>
      {children}
    </div>
  )
}

Section.Tab = function Tab ({ text, active }) {
  return (
    <div className={`section-tab ${active ? 'active' : ''}`}>
      <div>
        {text}
      </div>
    </div>
  )
}