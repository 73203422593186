import React from 'react'
import Steam from '../../images/steam.png'
import Github from '../../images/github.png'
import HackerRank from '../../images/hackerrank.png'
import Section from '../Section'
import Connection from '../Connection'

export default function AssociatedSites () {
    return (
      <Section>
        <Section.Body>
          <Connection
            site='GitHub'
            icon={Github}
            link='https://github.com/matthe815'
            labels={['Developer', 'Open Source']}
          />
          <Connection
            site='Steam'
            icon={Steam}
            link='https://steamcommunity.com/id/matthe815'
            labels={['Personal', 'Gaming']}
          />
          <Connection
            site='Hacker Rank'
            icon={HackerRank}
            link='https://www.hackerrank.com/matthe815'
            labels={['Developer', 'Coding']}
          />
            
        </Section.Body>
      </Section>
    )
}
