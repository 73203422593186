import React from 'react'
import { Container } from 'react-bootstrap'
import '../styles/Section.css'
import AssociatedSites from './sections/AssociatedSites'
import Projects from './sections/Projects'

const tabs = [<Projects />, <AssociatedSites />];

export default function MainBody ({ tab, subTab }) {
  return (
    <div className='content'>
      {tab == 0 ? <Projects subTab={subTab} /> : <AssociatedSites />}
    </div>
  )
}