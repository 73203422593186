import React from 'react'
import { Container } from 'react-bootstrap'
import '../styles/Section.css'

export default function TabbedSection ({ children }) {
  return (
    <Container className='section'>
      {children}
    </Container>
  )
}

TabbedSection.Header = function TabbedHeader ({ tabs, selected, onClick }) {
  return (
    <div className='section-header tabbed'>
      {tabs.map((tab) => (
          <TabbedSection.Tab key={tab} text={tab} active={selected === tab} onClick={onClick} />
      ))}
    </div>
  )
}

TabbedSection.Content = function Content ({ children, active }) {
  return (
    active && 
    <div className='section-body'>
      {children}
    </div>
  )
}

TabbedSection.Tab = function Tab ({ text, active, onClick }) {
    return (
      <div role='button' tabIndex={0} onKeyDown={() => {}} className={`section-tab ${active ? 'active' : ''}`} onClick={() => onClick(text)}>
          {text}
      </div>
    )
  }