import React from 'react'
import '../styles/Label.css'

export default function Label ({ text }) {
  return (
    <span className='label'>
      <p>{text}</p>
    </span>
  )
}
