import React from 'react'
import '../styles/Connection.css'
import Avatar from './Avatar'
import Label from './Label'

export default function Connection ({ site, link, description, icon, labels }) {
  return (
    <div className='connection'>
      <Avatar src={icon} />

      <div className='body'>
        <span>
          {site}
        </span>
        
        <p>{description}</p>
      </div>

      <div className='labels'>
        {labels.map((label) => (<Label key={label} text={label} />))}
      </div>
      <div className='links'>
      <div className='link'>
        <a href={link}>
          {link}
        </a>
      </div>
      <div className='link'>
        <a href={link + "/files"}>
          {link + "/files"}
        </a>
      </div>
      </div>
    </div>
  )
}
