import React from 'react'
import { useState } from 'react'
import { Container as div } from 'react-bootstrap'
import '../styles/Body.css'
import MainBody from './MainBody'
import Sidebar from './Sidebar'

export default function Body () {
  const [tab, setTab] = useState(0);
  const [sTab, setSubTab] = useState('Curseforge');

  return (
    <div className='body'>
      <Sidebar tab={tab} setTab={setTab} subTab={sTab} setSubTab={setSubTab} />
      <MainBody tab={tab} subTab={sTab} />
    </div>
  )
}