import React, { useContext } from 'react'
import { LanguageContext } from '../pages';
import '../styles/Section.css'

export default function Sidebar ({ tab, setTab, subTab, setSubTab }) {
  const context = useContext(LanguageContext);

  switch (context) {
    default:
    case "English":
      return (<div className='sidebar'>
      <Sidebar.Button index={0} current={tab === 0} text="Projects" setTab={setTab}>
        <Sidebar.SubButton index={'Curseforge'} current={subTab === 'Curseforge'} text="Curseforge" setTab={setSubTab} />
        <Sidebar.SubButton index={'Nexus'} current={subTab === 'Nexus'} text="Nexus" setTab={setSubTab} />
        <Sidebar.SubButton index={'Misc'} current={subTab === 'Misc'} text="Misc" setTab={setSubTab} />
      </Sidebar.Button>
      <Sidebar.Button index={1} current={tab === 1} text="Connections" setTab={setTab} />
    </div>)
    case "Japanese":
      return(<div className='sidebar'>
      <Sidebar.Button index={0} current={tab === 0} text="プロジェクト" setTab={setTab}>
        <Sidebar.SubButton index={'Curseforge'} current={subTab === 'Curseforge'} text="Curseforge" setTab={setSubTab} />
        <Sidebar.SubButton index={'Nexus'} current={subTab === 'Nexus'} text="Nexus" setTab={setSubTab} />
        <Sidebar.SubButton index={'Misc'} current={subTab === 'Misc'} text="など" setTab={setSubTab} />
      </Sidebar.Button>
      <Sidebar.Button index={1} current={tab === 1} text="コネクション" setTab={setTab} />
    </div>)
  }
}

Sidebar.Button = ({ index, current, text, setTab, children }) => {
  return (
    <>
      <div className={`sidebar-button ${current === true ? "active" : ""}`} onClick={() => setTab(index)}>{text}</div>
      {current ? children : null}
    </>
  )
}

Sidebar.SubButton = ({ index, current, text, setTab, children }) => {
  return (
    <>
      <div className={`sidebar-subbutton ${current === true ? "active" : ""}`} onClick={() => setTab(index)}>{text}</div>
      {children}
    </>
  )
}